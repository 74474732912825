var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _c(
            "gov-body",
            [
              _vm._v("\n    For service location\n    "),
              _c(
                "gov-link",
                {
                  attrs: {
                    to: {
                      name: "service-locations-show",
                      params: { serviceLocation: _vm.original.id }
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.service.name) +
                      " at " +
                      _vm._s(_vm.location.address_line_1)
                  )
                ]
              ),
              _vm._v(".\n  ")
            ],
            1
          ),
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("From")
                      ]),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("To")
                      ])
                    ],
                    1
                  ),
                  _vm.serviceLocation.name
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Name")]
                          ),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.original.name))
                          ]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.serviceLocation.name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.serviceLocation.hasOwnProperty("regular_opening_hours")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Regular opening hours")]
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.original.regular_opening_hours.length > 0
                                ? _c(
                                    "gov-list",
                                    _vm._l(
                                      _vm.original.regular_opening_hours,
                                      function(regularOpeningHour, index) {
                                        return _c("li", {
                                          key: index,
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.formatRegularOpeningHour(
                                                regularOpeningHour
                                              )
                                            )
                                          }
                                        })
                                      }
                                    ),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.serviceLocation.regular_opening_hours.length >
                              0
                                ? _c(
                                    "gov-list",
                                    _vm._l(
                                      _vm.serviceLocation.regular_opening_hours,
                                      function(regularOpeningHour, index) {
                                        return _c("li", {
                                          key: index,
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.formatRegularOpeningHour(
                                                regularOpeningHour
                                              )
                                            )
                                          }
                                        })
                                      }
                                    ),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.serviceLocation.hasOwnProperty("holiday_opening_hours")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Holiday opening hours")]
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.original.holiday_opening_hours.length > 0
                                ? _c(
                                    "gov-list",
                                    _vm._l(
                                      _vm.original.holiday_opening_hours,
                                      function(holidayOpeningHour, index) {
                                        return _c("li", {
                                          key: index,
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.formatHolidayOpeningHour(
                                                holidayOpeningHour
                                              )
                                            )
                                          }
                                        })
                                      }
                                    ),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.serviceLocation.holiday_opening_hours.length >
                              0
                                ? _c(
                                    "gov-list",
                                    _vm._l(
                                      _vm.serviceLocation.holiday_opening_hours,
                                      function(holidayOpeningHour, index) {
                                        return _c("li", {
                                          key: index,
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.formatHolidayOpeningHour(
                                                holidayOpeningHour
                                              )
                                            )
                                          }
                                        })
                                      }
                                    ),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.serviceLocation.hasOwnProperty("image_file_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Image")]
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.original.image
                                ? _c("ck-image", {
                                    attrs: { "file-id": _vm.original.image.id }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.serviceLocation.image_file_id
                                ? _c("ck-image", {
                                    attrs: {
                                      "file-id":
                                        _vm.serviceLocation.image_file_id
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "ck-logo",
                                    attrs: {
                                      src: _vm.apiUrl(
                                        "/service-locations/" +
                                          _vm.serviceLocation.id +
                                          "/image.png?update_request_id=" +
                                          _vm.updateRequestId
                                      ),
                                      alt: "Service location image"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }