var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - View Update Request" }
      }),
      _c(
        "gov-back-link",
        { attrs: { to: { name: "update-requests-index" } } },
        [_vm._v("Back to update requests")]
      ),
      _c(
        "gov-main-wrapper",
        [
          _vm.loading
            ? _c("ck-loader")
            : _c(
                "gov-grid-row",
                [
                  _c(
                    "gov-grid-column",
                    { attrs: { width: "full" } },
                    [
                      _c(
                        "gov-error-summary",
                        { attrs: { title: "Check this page" } },
                        [
                          _vm._v(
                            "\n          The update request needs to be confirmed. Check the page for\n          inconsistencies, errors that would be problematic to a user.\n        "
                          )
                        ]
                      ),
                      _c("gov-heading", { attrs: { size: "m" } }, [
                        _vm._v("View update request")
                      ]),
                      _vm.updateRequest.updateable_type === "organisations" ||
                      _vm.updateRequest.updateable_type ===
                        "new_organisation_created_by_global_admin"
                        ? _c("organisation-details", {
                            attrs: {
                              "update-request-id": _vm.updateRequest.id,
                              "requested-at": _vm.updateRequest.created_at,
                              organisation: _vm.updateRequest.data
                            }
                          })
                        : _vm.updateRequest.updateable_type ===
                          "organisation_sign_up_form"
                        ? _c("organisation-sign-up-form-details", {
                            attrs: {
                              "update-request-id": _vm.updateRequest.id,
                              "requested-at": _vm.updateRequest.created_at,
                              user: _vm.updateRequest.data.user,
                              organisation: _vm.updateRequest.data.organisation,
                              service: _vm.updateRequest.data.service
                            }
                          })
                        : _vm.updateRequest.updateable_type === "services" ||
                          _vm.updateRequest.updateable_type ===
                            "new_service_created_by_org_admin" ||
                          _vm.updateRequest.updateable_type ===
                            "new_service_created_by_global_admin"
                        ? _c("service-details", {
                            attrs: {
                              "update-request-id": _vm.updateRequest.id,
                              "requested-at": _vm.updateRequest.created_at,
                              service: _vm.updateRequest.data
                            }
                          })
                        : _vm.updateRequest.updateable_type === "locations"
                        ? _c("location-details", {
                            attrs: {
                              "update-request-id": _vm.updateRequest.id,
                              "requested-at": _vm.updateRequest.created_at,
                              location: _vm.updateRequest.data
                            }
                          })
                        : _vm.updateRequest.updateable_type ===
                          "service_locations"
                        ? _c("service-location-details", {
                            attrs: {
                              "update-request-id": _vm.updateRequest.id,
                              "requested-at": _vm.updateRequest.created_at,
                              "service-location": _vm.updateRequest.data
                            }
                          })
                        : _vm.updateRequest.updateable_type ===
                            "organisation_events" ||
                          _vm.updateRequest.updateable_type ===
                            "new_organisation_event_created_by_org_admin"
                        ? _c("organisation-event-details", {
                            attrs: {
                              "update-request-id": _vm.updateRequest.id,
                              "requested-at": _vm.updateRequest.created_at,
                              event: _vm.updateRequest.data
                            }
                          })
                        : _vm.updateRequest.updateable_type === "pages" ||
                          _vm.updateRequest.updateable_type === "new_page"
                        ? _c("page-details", {
                            attrs: {
                              "update-request-id": _vm.updateRequest.id,
                              "requested-at": _vm.updateRequest.created_at,
                              page: _vm.updateRequest.data
                            }
                          })
                        : _c("gov-body", [_vm._v("Update request is invalid")]),
                      _c("gov-section-break", { attrs: { size: "xl" } }),
                      _vm.auth.canEdit("update request") &&
                      !_vm.updateRequest.approved_at &&
                      !_vm.updateRequest.deleted_at
                        ? [
                            _c("gov-heading", { attrs: { size: "m" } }, [
                              _vm._v("Do you approve these changes?")
                            ]),
                            _c(
                              "gov-radios",
                              { attrs: { inline: "" } },
                              [
                                _c("gov-radio", {
                                  attrs: {
                                    id: "approve",
                                    name: "approve",
                                    label: "Approve",
                                    value: "approve"
                                  },
                                  model: {
                                    value: _vm.approve,
                                    callback: function($$v) {
                                      _vm.approve = $$v
                                    },
                                    expression: "approve"
                                  }
                                }),
                                _c("gov-radio", {
                                  attrs: {
                                    id: "reject",
                                    name: "approve",
                                    label: "Reject",
                                    value: "reject"
                                  },
                                  model: {
                                    value: _vm.approve,
                                    callback: function($$v) {
                                      _vm.approve = $$v
                                    },
                                    expression: "approve"
                                  }
                                }),
                                _c("gov-radio", {
                                  attrs: {
                                    id: "approve_edit",
                                    name: "approve_edit",
                                    label: "Approve and Edit",
                                    value: "approve_edit"
                                  },
                                  model: {
                                    value: _vm.approve,
                                    callback: function($$v) {
                                      _vm.approve = $$v
                                    },
                                    expression: "approve"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.approve === "reject"
                              ? _c("ck-textarea-input", {
                                  attrs: {
                                    id: "updateRequestRejectionMessage",
                                    value: _vm.form.message,
                                    label: "Rejection message",
                                    hint:
                                      "Explain to the submitter why this update request is being rejected (required)",
                                    maxlength: 1000,
                                    rows: 3,
                                    error: _vm.form.$errors.get("message")
                                  },
                                  on: {
                                    input: function($event) {
                                      _vm.form.message = $event
                                    }
                                  }
                                })
                              : _vm._e(),
                            _c("gov-section-break", { attrs: { size: "l" } }),
                            _vm.submitting
                              ? _c(
                                  "gov-button",
                                  { attrs: { disabled: "", type: "submit" } },
                                  [_vm._v("Submitting...")]
                                )
                              : _c(
                                  "gov-button",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.approve === null ||
                                        (_vm.approve === false &&
                                          _vm.form.message.length === 0),
                                      type: "submit"
                                    },
                                    on: { click: _vm.onSubmit }
                                  },
                                  [_vm._v("Submit")]
                                )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }