var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _vm.original
            ? _c(
                "gov-body",
                [
                  _vm._v("\n    For organisation\n    "),
                  _c("gov-link", {
                    attrs: {
                      to: {
                        name: "organisations-show",
                        params: { organisation: _vm.original.id }
                      }
                    },
                    domProps: { textContent: _vm._s(_vm.original.name) }
                  }),
                  _vm._v(".\n  ")
                ],
                1
              )
            : _vm._e(),
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "body" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _c("gov-table-header", { attrs: { scope: "column" } }),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("From")
                      ]),
                      _c("gov-table-header", { attrs: { scope: "column" } }, [
                        _vm._v("To")
                      ])
                    ],
                    1
                  ),
                  _vm.organisation.hasOwnProperty("url")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("URL")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", { attrs: { break: "" } }, [
                                _vm._v(_vm._s(_vm.original.url))
                              ])
                            : _c("gov-table-cell", [_vm._v("-")]),
                          _c("gov-table-cell", { attrs: { break: "" } }, [
                            _vm._v(_vm._s(_vm.organisation.url))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("name")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Name")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.name))
                              ])
                            : _c("gov-table-cell", [_vm._v("-")]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("slug")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Slug")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.slug))
                              ])
                            : _c("gov-table-cell", [_vm._v("-")]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.slug))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("email")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Email")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.email || "-"))
                              ])
                            : _c("gov-table-cell", [_vm._v("-")]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.email || "-"))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("phone")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Phone")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", [
                                _vm._v(_vm._s(_vm.original.phone || "-"))
                              ])
                            : _c("gov-table-cell", [_vm._v("-")]),
                          _c("gov-table-cell", [
                            _vm._v(_vm._s(_vm.organisation.phone || "-"))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("social_medias")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Social medias")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                { attrs: { break: "" } },
                                [
                                  _vm.original.hasOwnProperty(
                                    "social_medias"
                                  ) && Array.isArray(_vm.original.social_medias)
                                    ? _c(
                                        "gov-list",
                                        _vm._l(
                                          _vm.original.social_medias,
                                          function(socialMedia, index) {
                                            return _c(
                                              "li",
                                              { key: "social_media." + index },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "govuk-!-font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f(
                                                          "socialMediaType"
                                                        )(socialMedia.type)
                                                      ) + ":"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(socialMedia.url) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : [_vm._v("None")]
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "gov-table-cell",
                            { attrs: { break: "" } },
                            [
                              Array.isArray(_vm.organisation.social_medias)
                                ? _c(
                                    "gov-list",
                                    _vm._l(
                                      _vm.organisation.social_medias,
                                      function(socialMedia, index) {
                                        return _c(
                                          "li",
                                          { key: "social_media." + index },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "govuk-!-font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("socialMediaType")(
                                                      socialMedia.type
                                                    )
                                                  ) + ":"
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(socialMedia.url) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : [_vm._v("None")]
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("description")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Description")]
                          ),
                          _vm.original
                            ? _c("gov-table-cell", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.toHtml(_vm.original.description)
                                  )
                                }
                              })
                            : _c("gov-table-cell", [_vm._v("-")]),
                          _c("gov-table-cell", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.toHtml(_vm.organisation.description)
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.organisation.hasOwnProperty("logo_file_id")
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-header",
                            { attrs: { top: "", scope: "row" } },
                            [_vm._v("Logo")]
                          ),
                          _vm.original
                            ? _c(
                                "gov-table-cell",
                                [
                                  _vm.original.image
                                    ? _c("ck-image", {
                                        attrs: {
                                          "file-id": _vm.original.image.id
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c("gov-table-cell", [_vm._v("-")]),
                          _c(
                            "gov-table-cell",
                            [
                              _vm.organisation.logo_file_id
                                ? _c("ck-image", {
                                    attrs: {
                                      "file-id": _vm.organisation.logo_file_id
                                    }
                                  })
                                : _vm.organisation.id
                                ? _c("img", {
                                    staticClass: "ck-logo",
                                    attrs: {
                                      src: _vm.apiUrl(
                                        "/organisations/" +
                                          _vm.organisation.id +
                                          "/logo.png?update_request_id=" +
                                          _vm.updateRequestId
                                      ),
                                      alt: "Organisation logo"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }